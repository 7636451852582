.CustomMedia {
  display: grid;
  grid-gap: 20px;
}

.CustomMedia__description {
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 24px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}

.CustomMedia__select {
  margin-top: 12px;
  input {
    padding:8px 12px;
  }
  & :global(.MultiSelect__name) {
    min-width: unset;
  }
}

.CustomMedia__feedName {
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    margin-left: 4px;
    &:hover {
      opacity: 0.7;
    }
  }
}

.CustomMedia__removeIcon {
  height: 12px;
  width: 12px;
}
