.Params {
  position: relative;
}

.Params__item {margin: 4px 0;}
.Params__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #000000;
}

.Params__value {
  font-size: 16px;
  line-height: 22px;

  color: #000000
}

.Modal__content {
  display: flex;
  flex-flow: column nowrap;align-items: center;
}

.Modal__actionText {

  display: block;
  margin: 20px 0;
  font-weight: 600;
  text-align: center;
  max-width: 350px;
  font-size: 24px;
  line-height: 33px;

  color: #000000;
}


.Modal__buttons {
  display: flex;
  margin: 20px 0;
  & button:first-child {
    margin-right: 20px;
  }
}

.ProjectCard__dropdown {
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
  &:hover {
    .ProjectCard__dropdownContent {
      opacity: 1;
    }
  }
}

.ProjectCard__dropdownContent {
  opacity: 0;
  position: absolute;
  right: -2px;
  top: 25px;
  background: #606060;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 14px;
  //min-width: 88px;
  font-weight: 300;
  /* Sartorius White */

  color: #FFFFFF;
  & div {
    white-space: nowrap;
  }
}

.ProjectCard__dropdownItem {
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
}
