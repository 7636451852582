.ModalForExport {
  display: flex;
  flex-flow: column nowrap;
}

.ModalForExport__item {
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.ModalForExport__itemText {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-left: 11px;
}

.ModalForExport__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  button:first-child {
    margin-right: 16px;
  }
}
