.ProjectSettings__title {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  display: flex;
  align-items: center;
}
.ProjectSettings__titleMain {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
}
.ProjectSettings__titleName {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.ProjectSettings__content {
  padding: 16px 48px;
}

.ProjectSettings__shortInfo {
  display: flex;
}

.ProjectSettings__shortInfoItem {
  margin-right: 56px;
}

.ProjectSettings__units {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 48px;
}

.ProjectSettings__kineticLimits {
  display: grid;
  grid-template-columns: repeat(5, auto) 1fr;
  grid-gap: 48px;
}

.ProjectSettings__solverFirstRow, .ProjectSettings__inhibitionAndToxicity {
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  grid-gap: 48px;
}

.ProjectSettings__solverSecondRow {
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  grid-gap: 48px;
  margin-top: 40px;
}

.ProjectSettings__inputParameter {
  max-width: 350px;
}

.ProjectSettings__inputParams__wideInput {
  .Radio__input {
    width: 108px;
    input {
      padding: 0;
      text-align: center;
    }
  }
}

.ProjectSettings__heading {
  margin-top: 48px;
  margin-bottom: 12px;
}

.ProjectSettings__headingH2 {
  margin-bottom: 12px;
}

.ProjectSettings__row {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 48px;
}

.ProjectSettings__actionButtons {
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  grid-gap: 16px;
  margin-top: 32px;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.ProjectSettings__actionButtonAdvanced {
  cursor: pointer;
  &:hover {
    background: #ffed00;
  }
}
.Radio__title,
.MaxMinField__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #000000;
  margin-bottom: 12px;
}

.Radio__option {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  margin: 4px 0;
  min-height: 33px;
}
.Radio__checkWrapper {
  background: #ffffff;
  border: 2px solid #606060;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin-right: 10px;
}

.Radio__check {
  background: #606060;
  border-radius: 100%;
  //padding: 2px;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
}

.Radio__input {
  width: 60px;
}

.RadioWithInputs {
  .Radio__option {
    display: grid;
    grid-template-columns: auto 1fr 60px;
  }
  .Radio__optionPlaceholder {
    min-width: 30px;
  }
}
.MaxMinField__items {
  display: flex;
  align-items: center;
}

.MaxMinField__input {
  width: 108px;
  input {
    padding: 0px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

.MaxMinField__item {
  margin-right: 40px;
}

.MaxMinField__itemPlaceholder {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  color: #606060;
  margin-top: 4px;
}
