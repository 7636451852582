.ItsMockedData {
  height: 50px;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFED00;
}
