.TableHeadingSelect {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.TableHeadingSelect_open {
  .TableHeadingSelect__name {
    svg {
      transform: rotate(180deg);
    }
  }
  .TableHeadingSelect__options {
    height: 100%;
    max-height: 100px;
    overflow: auto;
  }
}

.TableHeadingSelect__name {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  margin: 8px 0;

  color: #000000;
  svg {
    margin-left: 4px;
    height: 10px;
    width: 10px;
  }
}

.TableHeadingSelect__options {
  display: flex;
  flex-flow: column nowrap;
  height: 0;
  overflow: hidden;
}

.TableHeadingSelect__option {
  font-size: 12px;
  line-height: 16px;

  color: #303030;
  margin: 4px 0;
}

.TableHeadingSelect__option_selected {
  font-weight: bold;
}
