.ProjectSettings__title {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
}
.ProjectSettings__titleName {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
}
.ProjectSettings__content {
  padding: 16px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ModelConfiguring__frames {
  padding: 16px 48px;
  display: grid;
  grid-template-columns: 70vw 1fr;
  grid-gap: 20px;
  height: 478px;
}

.ModelConfiguring__tableFrame {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.ModelConfiguring__frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 12px;
  flex-grow: 1;
}
.ModelConfiguring__frameTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #606060;
}

.ModelConfiguring__calcParams {
  margin-left: 4px;
  color: rgba(0, 0, 255, 0.5);
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 255, 0.3);
  }
}

.ModelConfiguring__tableFrameHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ModelConfiguring__contentLeft {
  display: flex;
  align-items: flex-end;
}
.ProjectSettings__shortInfo {
  display: flex;
}

.ProjectSettings__shortInfoItem {
  margin-right: 56px;
}

.ProjectSettings__units {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 48px;
}

.ProjectSettings__kineticLimits {
  display: grid;
  grid-template-columns: repeat(5, auto) 1fr;
  grid-gap: 48px;
}

.ProjectSettings__solverFirstRow,
.ProjectSettings__inhibitionAndToxicity {
  display: grid;
  grid-template-columns: repeat(2, auto) 1fr;
  grid-gap: 48px;
}

.ProjectSettings__solverSecondRow {
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  grid-gap: 48px;
  margin-top: 40px;
}

.ProjectSettings__inputParameter {
  max-width: 350px;
}

.ProjectSettings__heading {
  margin-top: 48px;
  margin-bottom: 12px;
}

.ProjectSettings__row {
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-gap: 48px;
}

.ProjectSettings__actionButtons {
  display: grid;
  grid-template-columns: repeat(3, auto) 1fr;
  grid-gap: 16px;
  margin-top: 32px;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}

.ProjectSettings__actionButtonAdvanced {
  cursor: pointer;
  &:hover {
    background: #ffed00;
  }
}
.Radio__title,
.MaxMinField__title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #000000;
  margin-bottom: 12px;
}

.Radio__option {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  margin: 4px 0;
  min-height: 33px;
}
.Radio__checkWrapper {
  background: #ffffff;
  border: 2px solid #606060;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  margin-right: 10px;
}

.Radio__check {
  background: #606060;
  border-radius: 100%;
  //padding: 2px;
  width: 12px;
  height: 12px;
  box-sizing: border-box;
}

.Radio__input {
  width: 60px;
}

.RadioWithInputs {
  .Radio__option {
    display: grid;
    grid-template-columns: auto 1fr 60px;
  }
  .Radio__optionPlaceholder {
    min-width: 30px;
  }
}
.MaxMinField__items {
  display: flex;
  align-items: center;
}

.MaxMinField__input {
  width: 60px;
}

.MaxMinField__item {
  margin-right: 40px;
}

.MaxMinField__itemPlaceholder {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  color: #606060;
  margin-top: 4px;
}

.ModelConfiguring__saveDate {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-left: 12px;

  color: #606060;
}
.ModelConfiguring__saveDateIcon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.ModelConfiguring__table {
  border: 1px solid #606060;
  overflow-x: auto;
  max-height: calc(100vh - 300px);
  scroll-behavior: smooth;
  user-select: none;
}

.ModelConfiguring__tableSelect {
  border: none;
  background-color: transparent;
  font-size: 11px;
  line-height: 15px;

  color: #000000;
  margin-top: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    border: none;
    outline: none;
  }
}

.ModelConfiguring__tableTitle {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.ModelConfiguring__tableScrollIcon {
  z-index: 10;
  background: #ffed00;
  color: #000000;
  width: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(50% - 60px / 2);
  right: 0;
  position: absolute;
  cursor: pointer;
  &:hover {
    background: #000000;
    color: #ffed00;
  }
}
.Checkboxes {
  display: flex;
  align-items: center;
}

.Checkboxes__item {
  font-size: 12px;
  line-height: 16px;

  color: #303030;
  display: flex;
  align-items: center;
  margin-right: 8px;
  &:last-child {
    margin-right: 0;
  }
}

.Checkboxes__checkedIcon,
.Checkboxes__removeIcon {
  margin-right: 4px;
  width: 14px;
  height: 14px;
}

.Checkboxes__checkedIcon {
  color: #ffed00;
}

.ModelConfiguring__modalContent {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.ModelConfiguring__modal {
  max-width: 660px;
  z-index: 10000;
}

.ModelConfiguring__buttons {
  display: flex;
  margin: 20px 0;
  & button:first-child {
    margin-right: 20px;
  }
}

.ModelConfiguring__modalHeading,
.ModelConfiguring__actionText {
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  text-align: center;

  color: #000000;
}

.ModelConfiguring__actionText {
  display: block;
  margin: 20px 0;
  font-weight: 600;
  text-align: center;
  max-width: 350px;
}

.Buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > div:last-child {
    margin-left: 16px;
  }
}
