.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 48px;
  background: #FFED00;
}

.Header__left {
  display: flex;
  align-items: center;
}
.Header__right {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
.Header__admin {
  margin-right: 12px;
}
.Header__logo {
  margin-right: 16px;
  height: 18px;
}

.Header__title {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;

  color: #000000;
}

.Header__userIcon {
  margin-right: 16px;
}
