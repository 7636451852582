.ModalWithTextEditor {}

.ModalWithTextEditor__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  & > div:first-child {
    margin-right: 16px;
  }
}
