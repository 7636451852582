@import '../../css/tokens';

.Button {
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: $color_white;
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  border: none;
  text-decoration: none;
  background: $color_black;
  &:visited {
    color: $color_white;
  }
}

.Button_uppercase {
  text-transform: uppercase;
}

.Button_hoverVariant_white {
  &:hover {
    background: #000000;
    color: #ffffff;
  }
}



.Button__leftIcon, .Button__rightIcon {
  background: #ffed00;
  width: 40px;
  color: $color_black;
  //height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Button__wrapper {
  display: flex;
  cursor: pointer;
  &:hover {
    .Button {
      background: #ffffff;
      color: #000000;
    }
  }
}

.Button__wrapper_variant_action {
  .Button {

    color: $color_black;
    background-color: $color_yellow;
    &:hover {
      background: $color_black;
      color: #ffffff;
    }
  }
}

.Button__wrapper_hoverVariant_action {
  &:hover {
    .Button {
      color: $color_black;
      background-color: $color_yellow;
    }
    .Button__leftIcon, .Button__rightIcon {
      background-color: $color_black;
      color: $color_yellow;
    }
  }
}

.Button__wrapper_size_small {
  height: 40px;
  .Button {
    font-size: 21px;
    line-height: 28px;
  }
}

.Button__wrapper_fullWidth {
  width: 100%;
  .Button {
    width: 100%;
  }
}

.Button__wrapper_disabled {
  opacity: 0.7;
  pointer-events: none;
}
