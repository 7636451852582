.AddEventInputResetModal {

}
.AddEventInputResetModal__label {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #000000;
  margin: 8px 0;
  i {
    font-weight: 300;
  }
}

.AddEventInputResetModal__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  & > div:first-child {
    margin-right: 16px;
  }
}

.AddEventInputResetModal__modalContent {
  max-height: 80vh;
  min-width: 50vw;
  overflow: auto;
}

.AddEventInputResetModal__groupTitle {
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;

  color: #000000;
  margin: 8px 0 4px 0;
}

.AddEventInputResetModal__groupValues {
  display: grid;
  grid-template-columns: repeat(4, 150px);
  grid-gap: 20px;
}
.AddEventInputResetModal__groupValue {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}

.AddEventInputResetModal__input {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.AddEventInputResetModal__unit {
  font-weight: 300;
}
