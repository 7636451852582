.EventRow {
  cursor: pointer;
}

.EventRow__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #606060;
  padding: 10px 0;
  font-weight: 600;
}

.EventRow__rowClock {
  font-size: 13px;
  display: flex;
  align-items: center;
  font-weight: 700;
  svg {
    margin-right: 4px;
    min-width: 16px;
    min-height: 16px;
  }
}

.EventRow__rowType {
  font-size: 16px;
}

.EventRow__arrow {
  margin-right: 8px;
}
