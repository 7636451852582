.CustomFunctionControls {
  display: flex;
  align-items: center;
}

.CustomFunctionControls__button {
  background: #F5F5F5;
  border: 1.5px solid #606060;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  box-sizing: border-box;
  cursor: pointer;
  &:first-child {
    margin-right: 0 !important;
  }
  &:hover {
    background-color: rgba(245, 245, 245, 0.6);
  }
  input {
    margin-right: 5px;
  }
}
