.AdditionalConfiguration__heading {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #606060;
  margin-bottom: 8px;
}

.AdditionalConfiguration__feedGrid {
  display: grid;
  grid-gap: 20px;
  border: 1px solid #606060;
  padding: 20px;
  max-width: 80vw;
  overflow: auto;
  width: min-content;
  padding-bottom: 80px;
  padding-right: 40px;
}
.AdditionalConfiguration__content {
  padding: 16px 48px;
}

.AdditionalConfiguration__feedName {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px;
  color: #000000;
}

.AdditionalConfiguration__feedType {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;

  color: #000000;
}

.AdditionalConfiguration__inputsGrid {
  display: grid;
  grid-template-columns: repeat(6, 200px);
  grid-gap: 20px;
  border: 1px solid #606060;
  padding: 20px;
  width: min-content;
}

.AdditionalConfiguration__inputsTitle {
  margin: 16px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #606060;
}

.AdditionalConfiguration__buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > div:last-child {
    margin-left: 16px;
  }
}
