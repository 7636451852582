.MetabolitesPage {

}

.MetabolitesPage__graphic {
  margin: 16px 48px;
}

.MetabolitesPage__subtitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  margin: 8px 48px;

  color: #606060;
}

.MetabolitesPage__table {
  margin: 16px 48px;
  border: 1px solid #BDBDBD;
}

.MetabolitesPage__modal {
  max-width: 660px;
  z-index: 10000;
}

.MetabolitesPage__modalContent {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.MetabolitesPage__modalHeading,
.MetabolitesPage__actionText {
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  text-align: center;

  color: #000000;
}

.MetabolitesPage__buttons {
  display: flex;
  margin: 20px 0;
  & button:first-child {
    margin-right: 20px;
  }
}
