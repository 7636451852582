.EventConfigurator {
  display: flex;
  flex-flow: column;
  max-width: 320px;
}

.EventConfigurator__content {
  border: 1px solid #BDBDBD;
}
.EventConfigurator__addEventButton {
  padding: 10px 20px;
  border-bottom: 1px solid #BDBDBD;
}


.EventConfigurator__events {
  margin: 10px 20px;
}

.EventConfigurator__title {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #606060;
  margin-bottom: 12px;
}
