.Breadcrumbs {
  background: #f5f5f5;
  padding: 0 50px;
  display: flex;
  align-items: center;
}
.Breadcrumbs__steps {
  display: flex;
  align-items: center;
  margin-left: 85px;
}
.Breadcrumbs__step {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #606060;
  opacity: 0.5;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
}

.Breadcrumbs__step_disabled {
  pointer-events: none;
}

.Breadcrumbs__activeStep {
  position: relative;
  opacity: 1;
  &:after {
    bottom: 0;
    position: absolute;
    content: '';
    width: 100%;
    height: 4px;
    background: #606060;
  }
}

.Breadcrumbs__icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.Breadcrumbs__removeIcon {
  color: red;
}
