.ActionButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  &>div:first-child {
    margin-right: 16px;
  }

}
