.SimulationDT {
}

.SimulationDT__content {
  padding: 16px 48px;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
}

.SimulationDT__contentLeft {
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
}
.SimulationDT__headingH2 {
  margin-bottom: 12px;
}

.SimulationDT__recalculateWrapper {
  background: #ffffff;
  border: 1px solid #bdbdbd;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SimulationDT__selectors {
  display: flex;
  align-items: center;
  & > div {
    margin-right: 16px;
  }
}

.SimulationDT__recalculateLabel {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  margin-bottom: 4px;
  color: #606060;
}

.SimulationDT__simulationDuration {
  display: flex;
  align-items: center;
  & > div:nth-child(2) {
    margin-left: 4px;
  }
}

.SimulationDT__simulationDurationInput {
  max-width: 65px;
}

.SimulationDT__editButton {
  max-width: 50px;
  background: #f5f5f5;
  border: 1.5px solid #606060;
  box-sizing: border-box;
  height: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.SimulationDT__editButton_disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.SimulationDT__main {
  display: grid;
  grid-template-columns: 320px 1fr;
  grid-gap: 20px;
  padding: 16px 48px;
}

.SimulationDT__mainGraphics {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.Fitting__recalculateLabel {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  margin-bottom: 4px;
  color: #606060;
  margin-top: 24px;
}
.Fitting__variablesGraphics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.Fitting__variablesGraphicsSelect {
  display: flex;
}
.Fitting__recalculateSelectWrapper {
  position: relative;
  &:after {
    position: absolute;
    right: 12px;
    top: 17px;
    width: 14px;
    height: 14px;

    content: '';
    background: url('./assets/select-arrow.svg') no-repeat;
  }
}
.Fitting__recalculateSelect {
  background: #f5f5f5;
  border: 1.5px solid #606060;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  height: 40px;
  padding: 0 36px 0 12px;
  color: #000000;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:focus {
    border: 1.5px solid #606060;
    outline: none;
  }
}
.Fitting__variableName {
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;

  color: #606060;
  margin-top: 10px;
}

.SimulationDT__graphicsTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #606060;
  margin-bottom: 12px;
}
