.Modal {
  margin: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 32px 64px;
  position: relative;
}

.Modal__close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.Modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  z-index: 11;
}

.Modal__title {
  font-weight: 300;
  font-size: 36px;
  line-height: 49px;
  color: #000000;
  text-align: center;
}

.Modal__content {
  margin-top: 24px;
}
