.Field {
  //display: flex;
}

.Field_fullWidth {
  width: 100%;
}

.Field__label {
  font-size: 21px;
  line-height: 29px;
  /* identical to box height */


  color: #606060;
  margin-bottom: 8px;
}

.Field__input {
  display: flex;
}

.Field__file {
  background: #000000;
  color: #FFFFFF;
  padding: 0 24px;
  margin-left: 24px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  &:hover {
    background: #FFED00;
    color: #000000;
  }
}

.forgotPassword {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  text-align: right;
  text-decoration-line: underline;

  color: #606060;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
