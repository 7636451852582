.Input {
  position: relative;
  input {
    width: 100%;
  }
}

.Input_fullWidth {
  width: 100%;

  .Input__wrapper {
    width: 100%;
  }
  .Input__input {
    width: 100%;
  }
}

.Input__wrapper {
  background: #F5F5F5;
  border: 1.5px solid #606060;
  display: flex;
}

.Input_error {
  &:hover .Input__downPlaceholder_error {
    display: flex;
  }
}

.Input__wrapper_error {
  border-color: red;
}
.Input__wrapper_size_large {
  height: 60px;
  .Input__input {
    font-size: 24px;
    line-height: 33px;
  }
}

.Input__wrapper_size_medium {
  height: 40px;
  .Input__input {
    font-size: 18px;
    line-height: 24px;
  }
}
.Input__wrapper_size_small {
  height: 30px;
  .Input__input {
    font-size: 18px;
    line-height: 24px;
  }
}

.Input__input {
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 300;
  padding: 0 16px;
}


.Input__downPlaceholder {
  position: absolute;
  bottom: -30px;
}
.Input__downPlaceholder_error {

  bottom: unset;
  display: none;
  align-items: center;
  background: #C10202;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  padding: 8px 16px;
  margin-top: 10px;
  z-index: 10;
  width: 100%;
}

.Input__icon {
  display: flex;
  align-items: center;
  justify-content: center;
margin-right: 12px;
}
