@import '../../css/tokens';

.Welcome {
  background-color: $color_yellow;
  min-height: 100vh;
  display: flex;
  font-family: 'Open Sans', sans-serif;
}

.Welcome__content {
  display: flex;
  flex-flow: column nowrap;

  align-items: center;
  max-width: 946px;
  margin: auto;
}

.Welcome__logo {
  margin-bottom: 20px;
  max-width: 437px;
}

.Welcome__heading {
  margin-bottom: 56px;
}

.Welcome__text {
  margin-bottom: 24px;
  text-align: center;
}
