.Projects__modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Projects__modalContentItem {
  margin-right: 48px;
  width: 210px;
  min-width: 210px;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    .Projects__modalContentItemImg {
      background-color: #000000;
      color: #FFED00;
      circle {
        fill: #000000 !important;
      }
    }
    .Projects__modalContentItemName {
      background-color: #FFED00;
      color: #000000;
    }
  }
}

.Projects__modalContentItem_hidden {
  opacity: 0.5;
  pointer-events: none;
}

.Projects__modalContentItemImg{
  background: #FFED00;
  min-height: 208px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Projects__modalContentItemName {
  font-weight: 600;
  font-size: 21px;
  line-height: 29px;
  text-align: center;
  background: #000000;
  padding: 16px 24px;
  color: #FFFFFF;
}

.Projects__renderQueue {

  background: #E5E5E5;
  padding: 20px;
  margin-bottom: 24px;
  margin-left: -20px;
}

.Projects__renderQueueHeader {
  margin-bottom: 12px;
}

.Projects__renderQueueWrapper {
  display: grid;
  grid-template-columns: repeat(3, 320px) 1fr;
  grid-auto-flow: row;
  grid-gap: 24px;
}
.Projects__projects {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 24px;
}

.Projects__projectsPlaceholder {
  grid-column: -2 / -1;
  grid-row: 1/-1;


}
.Projects__content {
  margin: 0 48px;
}

.Projects__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 24px;
}

.Projects__heading {
  margin-bottom: 24px;
}
