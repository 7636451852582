.AdminPage__filters {
  margin: 16px 48px;
  padding: 10px 20px;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AdminPage__controls {
  display: flex;
  align-items: center;
  & > div:not(:last-child) {
    margin-right: 16px;
  }
}
.AdminPage__label {
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  margin-bottom: 4px;
  color: #606060;
}

.AdminPage__input {
  background: #f5f5f5;
  border: 1.5px solid #606060;
  height: 52px;
  box-sizing: border-box;
  min-width: 320px;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  padding: 12px 12px;
  &:focus {
    border: 1.5px solid #606060;
    outline: none;
  }
}

.AdminPage__heading {
  padding: 16px 48px;
}

.AdminPage__th {
  background: #e5e5e5;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  vertical-align: top;
  color: #000000;
  text-align: left;
  &:not(:first-child) {
    min-width: 180px;
  }
}

.AdminPage__td, .AdminPage__th {
  padding: 6px 10px;
  border-right: 1.5px solid #bdbdbd;
}

.AdminPage_tr_highlighted {
  background: #f5f5f5;
}

.AdminPage__table {
  border-spacing: 0;
  position: relative;
}

.AdminPage__tableWrapper {
  margin: 16px 48px;
  border: 1px solid #606060;
  overflow-x: auto;
  max-height: calc(100vh - 300px);
  max-width: calc(100vw - 96px);
  scroll-behavior: smooth;
  -webkit-user-select: none;
  user-select: none;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
  min-height: 400px;
}

.AdminPage__icon {
  width: 24px;
  height: 24px;
  margin-left: 12px;
}

.AdminPage__buttons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 12px;
  gap: 12px;
}

.AdminPage__bulk {
  margin: 16px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
