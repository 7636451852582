.tr_clickable {
  cursor: pointer;
}
.tr_highlighted {
  background: #f5f5f5;
}

.tr_selected {
  background: #ffed00;
}

.Table {
  border-spacing: 0;
  position: relative;
}
.Table__th {
  min-width: 180px;
  background: #e5e5e5;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
  vertical-align: top;

  color: #000000;
  text-align: left;
}

.Table__thWrapper {
  display: flex;
  align-items: center;
}

.Table__thSorting {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin-left: 4px;
  color: #606060;
}

.Table__thSorting_sort_desc {
  transform: rotate(180deg);
}
.Table__trHead_absolute {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.Table__td,
.Table__th {
  padding: 6px 10px;
  border-right: 1.5px solid #bdbdbd;
  &:first-child {
    border-right: 2px solid #606060;
  }
  &:last-child {
    border-right: none;
  }
}
