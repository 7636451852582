.Checkbox {
  background: #FFFFFF;
  border: 2px solid #606060;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.Checkbox__checkedIcon {
  background: #606060;
  border: 2px solid #606060;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
