@import '../../css/tokens';

.Heading {
  font-weight: 300;
  font-size: 60px;
  margin: 0;
  line-height: 82px;
  //text-align: left;

  color: $color_black;
}

.Heading_size_h1 {
  font-size: 60px;
  line-height: 82px;
}

.Heading_size_h2 {
  font-size: 48px;
  line-height: 65px;
}

.Heading_size_h3 {
  font-size: 36px;
  line-height: 49px;
}