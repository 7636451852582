.Input {
  width: 170px;
}

.Input__name {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #000000;
  margin-bottom: 4px;
}

.Input__param {
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 4px;
  color: #606060;
}

.Input__paramName {
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 8px;
}

.Input__fieldName {
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;

  color: #606060;
  margin: 8px 0;
}

.Input__target {
  margin-top: 16px;
}

.Input__bounds {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
}
