.CalcParamsModal {

}

.CalcParamsModal__type {
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: bold;
  width: fit-content;
  position: relative;
  display: flex;
}

.CalcParamsModal__select {
  margin: 18px 0;
}

.CalcParamsModal__hints {
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: 400px;
  overflow: auto;
  margin-top: 8px;
  height: 40px;
}

.CalcParamsModal__hint {
  padding: 2px 4px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.CalcParamsModal__input__error {
  border: 1px solid red
}

.CalcParamsModal__infoTooltip {
  width: 100%;
  background-color: #606060;
  color: #ffffff;
  padding:  10px;
  font-size: 13px;
  line-height: 14px;
  position: absolute;
  top: 20px;
  left: calc(100% - 10px);
  z-index: 5;
  p {
    margin: 0;
  }
}
