.Login {
  display: flex;
  justify-content: center;
  background: #F2F2F2;
  min-height: 100vh;
}

.Login__wrapper {
  //margin-top: 96px;
  width: 500px;
  margin: auto;
  button {
    justify-content: center;
  }
}

.Login__head {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  min-height: 270px;
  background: #FFED00;
}

.Login__logo {
  max-width: 407px;
  margin-bottom: 8px;
}

.Login__content {
  padding: 24px 32px;
  background: #FFFFFF;
}

.Login__contentHeader {
  margin-bottom: 2vh;
}

.Login__contentFields {
  display: grid;
  grid-gap: 32px;
  margin-bottom: 4vh;
}
