.AddEventButton {
  position: relative;
  max-width: 180px;
}

.AddEventButton__button {
  font-weight: bold;
  font-size: 21px;
  line-height: 29px;
  color: #FFFFFF;
  padding: 0 15px;
  text-transform: uppercase;
display: flex;
  align-items: center;
  justify-content: space-between;
  background: #000000;
  height: 40px;
  cursor: pointer;
  svg {
    margin-left: 8px;
  }
  &:hover {
    background-color: rgba(0,0,0,0.8);
  }
}

.AddEventButton__options {
  background: #606060;
  display: flex;
  flex-flow: column nowrap;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;

  /* Sartorius White */

  color: #FFFFFF;
  padding: 5px 10px;
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 100;
}

.AddEventButton__option {
  cursor: pointer;
  margin: 2px 0;
  &:hover {
    opacity: 0.8;
  }

}
